import React from 'react';

const TrackerStates = {
  COMPLETE: { icon: 'fa fa-check', modifier: 'is-complete' },
  ACCEPTABLE: { icon: 'fa fa-check', modifier: 'is-acceptable' },
  INCOMPLETE: { icon: '', modifier: 'is-incomplete' },
  OPTIONAL: { icon: '', modifier: 'is-optional' },
  SKIPPED: { icon: 'fa fa-reply', modifier: 'is-skipped' },
  OVERDUE: { icon: 'fa fa-exclamation', modifier: 'is-overdue' },
  UPCOMING: { icon: '', modifier: 'is-upcoming' },
};

const GetTrackerStateForContent = content => {
  if (content.completed) return TrackerStates.COMPLETE;
  if (content.skipped) return TrackerStates.SKIPPED;
  if (content.optional) return TrackerStates.OPTIONAL;
  return TrackerStates.INCOMPLETE;
};

const GetTrackerStateForMilestone = milestone => {
  switch (milestone.progress_state) {
    case 'in_progress':
      return TrackerStates.INCOMPLETE;
    case 'completed':
      return TrackerStates.COMPLETE;
    case 'acceptable_completed':
      return TrackerStates.ACCEPTABLE;
    case 'overdue':
      return TrackerStates.OVERDUE;
    case 'not_started':
      return TrackerStates.UPCOMING;
    default:
      return TrackerStates.INCOMPLETE;
  }
};

const ProgressTracker = ({
  className = '',
  children,
}) => (
  <div className={`ProgressTracker ${className}`}>
    {children}
  </div>
);

const ProgressTrackerStep = ({
  state = TrackerStates.INCOMPLETE, children,
}) => (
  <div className={`ProgressTracker-step ${state.modifier}`}>
    <i className={`ProgressTracker-state ${state.icon}`} />
    {React.Children.map(children, child => React.cloneElement(child, {
      className: `ProgressTracker-item ${child.props.className}`,
    }))}
  </div>
);

export {
  ProgressTracker, ProgressTrackerStep, TrackerStates, GetTrackerStateForContent, GetTrackerStateForMilestone,
};
