const alertMessages = {
  0: `An error has occurred loading the page. Please check your internet connection.`,
  401: `You have been logged out. Please <a href="/sign-in">sign in</a> to continue.`,
  500: `An error has occurred loading the page. Please try again and contact <a href="mailto:support@kubicle.com" target="_blank">support@kubicle.com</a> if the problem persists.`,
};

const ajaxError = (status) => {
  const message = alertMessages[status] || alertMessages[500];
  KUBE.Alert.error(message);
};

export default ajaxError;