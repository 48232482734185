import React from 'react';
import Tooltip from './Tooltip';

const ProgressBar = ({
  percentage, heading, text, className = '', barClass = '', id = 'default-progress-bar', pulse = true, showPercentageText = true,
}) => (
  <div className={`ProgressBar ${pulse ? 'has-pulse' : ''} ${className}`}>
    <div className={`ProgressBar-bar ${barClass}`} data-tip data-for={`progress-${id}`}>
      <div className="ProgressBar-progress" style={{ width: `${percentage}%` }}>
        {showPercentageText && (
          <span className="ProgressBar-progressText js-tip-anchor">
            {percentage}
            %
          </span>
        )}
      </div>
    </div>
    {(heading || text) && (
      <Tooltip id={`progress-${id}`} place="top" heading={heading} text={text} />
    )}
  </div>
);

export default ProgressBar;
