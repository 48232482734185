import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enBackgroundOperation from './en/BackgroundOperation.json';
import enLearnerCheckIns from './en/LearnerCheckIns.json';
import enExam from './en/Exam.json';
import enRecommendedStep from './en/RecommendedStep.json';
import enErrors from './en/Errors.json';
import enGeneral from './en/General.json';
import enBulkUploader from './en/BulkUploader.json';
import enCopyToClipboard from './en/CopyToClipboard.json';
import enDynamicTable from './en/DynamicTable.json';
import enEllipsisMenu from './en/EllipsisMenu.json';
import enLearningGroupDashboard from './en/LearningGroupDashboard.json';
import enVideoPlayer from './en/VideoPlayer.json';
import enCertificates from './en/Certificates.json';
import enExercises from './en/Exercises.json';
import enLicenceDashboard from './en/LicenceDashboard.json';
import enSqlEditor from './en/SqlEditor.json';
import enVisitorPreviewCTA from './en/VisitorPreviewCTA.json';
import enSemester from './en/Semester.json';
import enSemesterForm from './en/SemesterForm.json';
import enLearnDashboard from './en/LearnDashboard.json';
import enLibrary from './en/Library.json';
import enLearningPaths from './en/LearningPaths.json';
import enProject from './en/Project.json';
import enLearningGroupAdminAssignment from './en/LearningGroupAdminAssignment.json';
import enWelcomeScreen from './en/WelcomeScreen.json';
import enLearningGroupPortal from './en/LearningGroupPortal.json';
import enIndividualTrial from './en/IndividualTrial.json';
import enNewToKubicle from './en/NewToKubicle.json';
import enRequestPasswordReset from './en/RequestPasswordReset.json';
import enSignInPanel from './en/SignInPanel.json';
import enPasswordReset from './en/PasswordReset.json';
import enCommon from './en/Common.json';
import enMilestoneWizard from './en/MilestoneWizard.json';
import enAccountDeactivations from './en/AccountDeactivations.json';
import enLearnerManagement from './en/LearnerManagement.json';
import enCalendarPreference from './en/CalendarPreference.json';
import enMandatoryProfileData from './en/MandatoryProfileData.json';
import enProgressStatuses from './en/ProgressStatuses.json';
import enOrganisationPerformance from './en/OrganisationPerformance.json';
import enAdminManagement from './en/AdminManagement.json';
import enLearningGroups from './en/LearningGroups.json';
import enProjectSubmission from './en/ProjectSubmission.json';
import enFileDropzone from './en/FileDropzone.json';
import enNavigation from './en/Navigation.json';
import enAlerts from './en/Alerts.json';

const resources = {
  en: {
    LearnerCheckIns: enLearnerCheckIns,
    Exam: enExam,
    RecommendedStep: enRecommendedStep,
    Errors: enErrors,
    General: enGeneral,
    BulkUploader: enBulkUploader,
    CopyToClipboard: enCopyToClipboard,
    DynamicTable: enDynamicTable,
    EllipsisMenu: enEllipsisMenu,
    LearningGroupDashboard: enLearningGroupDashboard,
    VideoPlayer: enVideoPlayer,
    Certificates: enCertificates,
    Exercises: enExercises,
    LicenceDashboard: enLicenceDashboard,
    SqlEditor: enSqlEditor,
    VisitorPreviewCTA: enVisitorPreviewCTA,
    LearnDashboard: enLearnDashboard,
    Semester: enSemester,
    SemesterForm: enSemesterForm,
    Library: enLibrary,
    LearningPaths: enLearningPaths,
    Project: enProject,
    LearningGroupAdminAssignment: enLearningGroupAdminAssignment,
    WelcomeScreen: enWelcomeScreen,
    BackgroundOperation: enBackgroundOperation,
    LearningGroupPortal: enLearningGroupPortal,
    IndividualTrial: enIndividualTrial,
    NewToKubicle: enNewToKubicle,
    RequestPasswordReset: enRequestPasswordReset,
    MilestoneWizard: enMilestoneWizard,
    SignInPanel: enSignInPanel,
    PasswordReset: enPasswordReset,
    AccountDeactivations: enAccountDeactivations,
    LearnerManagement: enLearnerManagement,
    CalendarPreference: enCalendarPreference,
    Common: enCommon,
    ProgressStatuses: enProgressStatuses,
    OrganisationPerformance: enOrganisationPerformance,
    AdminManagement: enAdminManagement,
    MandatoryProfileData: enMandatoryProfileData,
    LearningGroups: enLearningGroups,
    ProjectSubmission: enProjectSubmission,
    FileDropzone: enFileDropzone,
    Navigation: enNavigation,
    Alerts: enAlerts,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',

  keySeparator: '.',

  interpolation: {
    escapeValue: false,
    format: (value, format, lng) => {
      switch (format) {
        case 'formattedInt':
          return Intl.NumberFormat(lng).format(value);
        default:
          return value;
      }
    },
  },
});
export default i18n;
