import ReactTooltip from 'react-tooltip';
import React from 'react';

const Tooltip = ({
  id,
  place,
  type,
  heading,
  text,
  children,
}) => {
  let reactTooltipType = 'light';
  let typeClassName = 'Tooltip--primary';

  if (type === 'secondary') {
    reactTooltipType = 'dark';
    typeClassName = 'Tooltip--secondary';
  }

  return (
    <ReactTooltip id={id} place={place} type={reactTooltipType} className={`Tooltip ${typeClassName}`} effect="solid">
      {children && children}
      {!children && heading && <p className="Tooltip--heading">{heading}</p>}
      {!children && text && <p className="Tooltip--text">{text}</p>}
    </ReactTooltip>
  );
};
export default Tooltip;
