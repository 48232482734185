import React, { useEffect, useState } from 'react';

const Modal = ({
  children, isOpen, closeModal, className,
}) => {
  const transitionOutDuration = 300;
  const [display, setDisplay] = useState(isOpen);
  const [visibility, setVisibility] = useState(isOpen);

  useEffect(() => {
    let timeout;

    if(isOpen) {
      setDisplay(true);
      timeout = setTimeout(() => setVisibility(true), 10);
    } else {
      timeout = setTimeout(() => setDisplay(false), transitionOutDuration);
      setVisibility(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen]);

  return (
    <div className={className}>
      <div className="kube-modal-bg" style={{ display: display ? 'block' : 'none', opacity: visibility ? 1 : 0 }} onClick={closeModal} />
      <div className="kube-modal" style={{ display: display ? 'block' : 'none', opacity: visibility ? 1 : 0, visibility: 'visible' }}>
        <a className="close-kube-modal" onClick={closeModal}><i className="fa fa-times" aria-hidden="true" /></a>
        {children}
      </div>
    </div>
  );
};

export default Modal;
